import TYPES from '@/types';

// Application
import GetGoalDefinitionStepQuery
  from '@/modules/flagship/goal-definition-step/application/queries/get-goal-definition-step-query';
import UpdateGoalDefinitionStepCommand
  from '@/modules/flagship/goal-definition-step/application/commands/update-goal-definition-step-command';

// Domain
import {
  GoalDefinitionStepEntity,
} from '@/modules/flagship/goal-definition-step/domain/entities/goal-definition-step-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class FlagshipGoalsWizardModalViewModel {
  @Inject(TYPES.GET_GOAL_DEFINITION_STEP_QUERY)
  private readonly get_goal_definition_step_query!: GetGoalDefinitionStepQuery;

  @Inject(TYPES.UPDATE_GOAL_DEFINITION_STEP_COMMAND)
  private readonly update_goal_definition_step_cmd!: UpdateGoalDefinitionStepCommand;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.flagship.flagship-goals.flagship_goals_wizard_modal';

  readonly STEPS = [
    {
      step: 0,
      main_item: 'emergency_fund',
      secondary_item: 'calculation',
      component: 'FlagshipGoalsWizardEmergencyFund',
    },
    {
      step: 1,
      main_item: 'emergency_fund',
      secondary_item: 'how_do_you_save',
      component: 'FlagshipGoalsWizardCustomizeFund',
    },
    {
      step: 2,
      main_item: 'custom',
      secondary_item: 'goal_selection',
      component: 'FlagshipGoalsWizardPersonalGoal',
    },
    {
      step: 3,
      main_item: 'custom',
      secondary_item: 'calculation',
      component: 'FlagshipGoalsWizardCustomizeGoal',
    },
    {
      step: 4,
      main_item: 'retirement',
      secondary_item: 'calculation',
      component: 'FlagshipGoalsWizardRetirementFundSecond',
    },
    {
      step: 5,
      main_item: 'retirement',
      secondary_item: 'current_amount',
      component: 'FlagshipGoalsWizardRetirementFund',
    },
    {
      step: 6,
      main_item: 'goal_setting',
      secondary_item: '',
      component: 'FlagshipGoalsWizardBeforeAdjustGoals',
    },
    {
      step: 7,
      main_item: 'goal_setting',
      secondary_item: '',
      component: 'FlagshipGoalsWizardAdjustGoals',
    },
    {
      step: 8,
      main_item: '',
      secondary_item: '',
      component: 'FlagshipGoalsWizardProcess',
    },
  ];

  current_step = 0;

  current_component = this.STEPS[this.current_step];

  is_loading = false;

  private goal_definition_step_entity: GoalDefinitionStepEntity = {
    main_item: '',
    secondary_item: '',
  }

  private readonly view: any;

  constructor(view: any) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    await this.loadGoalDefinitionStep();
  }

  loadGoalDefinitionStep = async () => {
    try {
      const goal_definition_step = await this.get_goal_definition_step_query.execute(false);

      const step_selected = this.STEPS.find(
        (step) => step.main_item === goal_definition_step.main_item
          && step.secondary_item === goal_definition_step.secondary_item,
      );

      if (step_selected) {
        this.current_step = step_selected.step!;
        this.current_component = this.STEPS[this.current_step];
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_goal_definition_step'));
    }
  };

  updateGoalDefinitionStep = async () => {
    try {
      this.goal_definition_step_entity.main_item = this.STEPS[this.current_step].main_item;
      // eslint-disable-next-line max-len
      this.goal_definition_step_entity.secondary_item = this.STEPS[this.current_step].secondary_item!;
      await this.update_goal_definition_step_cmd.execute(this.goal_definition_step_entity);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_goal_definition_step'));
    }
  };

  nextStep = async () => {
    await this.updateGoalDefinitionStep();

    if (this.current_step < this.STEPS.length - 1) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  prevStep = () => {
    if (this.current_step === 0) {
      this.view.endProcess();
      return;
    }

    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  endProcess= () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
  }

  changeLoadingStep = (loading: boolean) => {
    this.is_loading = loading;
  }
}
